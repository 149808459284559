<template>
  <div
    class="flip-card"
    @mouseover="showHelp=true"
    @mouseout="showHelp=false"
    v-bind:class="{ 'hover': showHelp }"
  >
    <div class="flip-card-inner">
      <div
        class="flip-card-front"
      >
        <div
          class="flip-card-front-img"
        >
          <img
            :src="require('@/assets/icons/'+analysis.cardIcon)"
            width="100%"
            height="100%"
          />
        </div>
        <div class="flip-card-front-title">
          {{ analysis.title }}
        </div>
        <div
          class="flip-card-front-subtitle"
        >
          {{ analysis.description }}
        </div>
      </div>
      <div
        @click="$emit('click')"
        class="flip-card-back"
      >
        <div class="flip-card-back-title">
          {{ analysis.title }}
        </div>
        <div class="flip-card-back-img" />
        <div
          class="flip-card-back-text"
        >
          <component :is="helpComponent" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnalysisCard',
  props: {
    analysis: {
      type: Object,
      default: null
    },
    buttonLabel: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showHelp: false
    }
  },
  computed: {
    helpComponent: function () {
      let helpComponent = null
      if (this.analysis.wfclass) {
        try {
          require('@/components/help/firstlevel/' + this.analysis.wfclass + '.vue')
          helpComponent = () => import('@/components/help/firstlevel/' + this.analysis.wfclass + '.vue')
        } catch (e) {
          helpComponent = null
        }
      } else {
        helpComponent = null
      }
      return helpComponent
    }
  }
}
</script>

<style scoped>
.flip-card {
  background-color: transparent;
  width: 100%;
  height: 420px;
  perspective: 1000px;
}
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}
.flip-card.hover .flip-card-inner {
  transform: rotateY(180deg);
}
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}
.flip-card-back {
  cursor: pointer;
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 30px #DED9D4;
  border-radius: 10px;
  color: black;
  transform: rotateY(180deg);
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 420px;
}
.flip-card-front {
  margin-right: 5px;
  width: 100%;
  height: 100%;
  margin-bottom: 25px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 30px #DED9D4;
  border-radius: 10px;
  transition: all ease-in-out 0.3s;
  border-bottom: 5px solid #fff;
  border-top: 5px solid #fff;
}
.flip-card-front-img {
  text-align: center;
}
.flip-card-footer {
    position: absolute;
    width: 100%;
    bottom: 15px;
    justify-content: center;
}
.flip-card-front-title {
  padding-right: 30px;
  padding-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.4px;
  color: #303133;
  text-align: center;
  font: normal normal 600 20px/26px Sans-serif;
  opacity: 1;
}
.flip-card-front-subtitle {
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font: normal normal normal 12px/18px Sans-serif;
  letter-spacing: 0.24px;
  color: #303133;
  opacity: 1;
}

.flip-card-back::after {
  content: '';
  background-image: url('../assets/img/logo_help.svg');
  background-repeat: no-repeat;
  background-position: bottom 120px right 110px;
  background-size: 45%;
  opacity: 0.4;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -10;
}
.flip-card-back-title {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: black;
  text-align: center;
  font: normal normal 600 20px/26px Sans-serif;
  opacity: 1;
}
.button.is-primary.is-inverted {
  background-color: #fff;
  color: #20BFF0;
  border-color: #20BFF0;
}
.flip-card-back-text {
  padding-top: 10px;
  padding-bottom: 40px;
  padding-right: 40px;
  padding-left: 40px;
  text-align: left;
}
ul {
  list-style: disc;
  display: inline-block;
  text-align: left;
  word-wrap: break-word;
}
ul ul {
  list-style: square;
  margin-left: 30px;
}
ul.card-help-list > li {
  margin-top: 10px;
}

</style>
